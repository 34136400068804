import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NavParams, ModalController } from '@ionic/angular';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from '@kolkov/ngx-gallery';
import { CartService } from 'src/app/services/cart.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { SweetalertsService } from 'src/app/services/sweetalerts.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-detail-add-product',
  templateUrl: './detail-add-product.component.html',
})
export class DetailAddProductComponent {
  @ViewChild('ngxGallery') ngxGallery!: any;
  @Input() selectedProduct;
  @Output() changeTab: EventEmitter<number> = new EventEmitter<number>();
  cantidad;
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  currentUser;
  constructor(
    public cartService: CartService,
    public modalController: ModalController,
    private sanitizer: DomSanitizer,
    public sweetalertsService: SweetalertsService,
    public firestoreService: FirestoreService,
    public userService: UserService,
    public router: Router
  ){
    this.userService.currentUserSubject$.subscribe((user)=>{
      this.currentUser=user;
    })
    this.cantidad = 1;
  }
  ngOnInit(){
    this.getProduct();
  }
  getProduct(){
    // this.selectedProduct = this.navParams.get('selectedProduct');
    console.info("selectedProduct22222: ",this.selectedProduct);
    this.galleryOptions = [
      {
        // width: '200px',
        // height: '100px',
        thumbnailsColumns: 4,
        imageSize:'contain',
        thumbnailSize:'contain',
        imageAnimation: NgxGalleryAnimation.Slide,
        preview: false
      },
      // max-width 800
      {
        breakpoint: 800,
        // width: '100%',
        // height: '600px',
        imagePercent: 70,
        // thumbnailsPercent: 20,
        // thumbnailsMargin: 20,
        // thumbnailMargin: 20,
        preview: false
      },
      // max-width 400
      {
        breakpoint: 400,
        imagePercent: 70,
        preview: false
      }
    ];
    this.getImages();
  }
  getImages(){
    if(this.selectedProduct.urlImagen && this.selectedProduct.urlImagen.length > 0) {
      this.galleryImages = this.selectedProduct.urlImagen.map(imageUrl => {
        return {
          small: imageUrl,
          medium: imageUrl,
          big: imageUrl
        };
      });
    } else {
      this.galleryImages = [];
    }
  }
  getSafeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
  public qMinus(){
    if(+this.cantidad>1){
      this.cantidad = +this.cantidad-1;
    }
  }
  public qPlus(){
    if(+this.cantidad<this.selectedProduct.stock?.qty){
      this.cantidad = +this.cantidad+1;
    }
  }
  addProductToCartDirect(){
    this.cartService.addProductToCart(this.selectedProduct,this.cantidad);
    this.router.navigate(['/main/checkout']);
  }
  addProductToCart(){
    this.sweetalertsService.questionAlert("¿Añadir al carrito?","Si","No","Procesando").then((resp)=>{
      if(resp){
        this.cartService.addProductToCart(this.selectedProduct,this.cantidad);
      }
    })
  }
  saveFavorite(item){
    this.sweetalertsService.questionAlert("¿Agregar a favoritos?","Si","No","Procesando").then((resp)=>{
      if(resp){
        let tempId=new Date().getTime();
        this.firestoreService.set(`empresas/SUBACOM/users/${this.currentUser.email}/favoritos/`,this.sanitizeProduct(Object.assign({},item,{active:true})),tempId).subscribe((resp)=>{
          this.sweetalertsService.generalSuccess("Favorito agregado");
        },(error)=>{
          this.sweetalertsService.generalError("No se pudo agregar a favoritos");
        });
      }
    });
  }
  sanitizeProduct(item){
    let tempProd=JSON.parse(JSON.stringify(item));
    delete tempProd.descripcionLargaHtml;
    delete tempProd.fichaTecnicaHtml;
    return tempProd;
  }
  goToCotizacionTab() {
    this.changeTab.emit(2);
  }
}
