import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs';
import { SweetalertsService } from './sweetalerts.service';
import { environment } from 'src/environments/environment.prod';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  currentUser;
  constructor(
    public http: HttpClient,
    public sweetalertsService: SweetalertsService,
    private userService: UserService,
    public router: Router,
    private sanitizer: DomSanitizer
  ) { 
    this.userService.currentUserSubject$.subscribe((user)=>{
      this.currentUser=user;
    })
  }
  listProductos(params){
    this.sweetalertsService.loading();
    const requestOptions = {
      headers: new HttpHeaders({
        // Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
        Authorization: `Bearer ${environment.token}`
      })
    };
    let request = this.http.get(`${environment.api_prod}${environment.version_prod}product/find?${params}`, requestOptions).pipe(
      map((resp:any) => {
        this.sweetalertsService.dissmissSwal();
        let list = resp['data']['result'];

        list = list.filter(item => item.stock); // Filtrar los elementos con stock mayor que 0

        list.forEach(item => {
          item.cantidad = 1; // Agregar el parámetro cantidad a cada producto
          if(item.stock){
            item.stock.qty=Number(item.stock.qty);
            item.stock.qty==0?item.stock=null:null;
          }else{
            item.stock=null;
          }
          if(item.urlImagen && typeof item.urlImagen === 'string') {//para serpararurlImagen
            item.urlImagen = item.urlImagen.split(',').map(image => image.trim());
          }
          if(item.fichaTecnicaHtml) {
            item.fichaTecnicaHtml = this.getSafeHtml(item.fichaTecnicaHtml);
          }
          if(item.descripcionLargaHtml) {
            item.descripcionLargaHtml = this.getSafeHtml(item.descripcionLargaHtml);
          }
          if(item.prices) {
            item.priceF = item.prices.discount;
          }
          if(item.marca) {
            item.marcaN = item.marca.name;
          }
          // let tempProd=JSON.parse(JSON.stringify(item));
          // delete tempProd.descripcionLargaHtml;
          // delete tempProd.fichaTecnicaHtml
          // item.clearProduct=tempProd;
        });
        return resp;
      }),
    )
    return request;
  }
  listBanners(params){
    // this.sweetalertsService.loading();
    const requestOptions = {
      headers: new HttpHeaders({
        // Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
        Authorization: `Bearer ${environment.token}`
      })
    };
    let request = this.http.get(`${environment.api_prod}${environment.version_prod}flyer?${params}`, requestOptions).pipe(
      // delay(1000),
      map((resp:any) => {
        // this.sweetalertsService.dissmissSwal();
        let lista_fñyers = resp['data'];
        // lista_fñyers=lista_fñyers.filter((m) => m.type == "H" && m.isActive == "Y");
        return lista_fñyers||[];
      }),
      // catchError((error: any) => {
      //   this.sweetalertsService.dissmissSwal();
      //   if (error.error.statusCode == 401) {
      //     this.sweetalertsService.generalWarning("Token expirado");
      //   } else {
      //     this.sweetalertsService.generalError(error.error.message);
      //   }
      //   throw error;
      // })
    )
    return request;
  }
  listCategories(){
    const requestOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${environment.token}`
      })
    };
    let request = this.http.get(`${environment.api_prod}${environment.version_prod}category/menu`, requestOptions).pipe(
      map((resp:any) => {
        let categories = resp['data'];
        return categories||[];
      }),
    )
    return request;
  }
  listMarcas(){
    const requestOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${environment.token}`
      })
    };
    let request = this.http.get(`${environment.api_prod}${environment.version_prod}mark/find`, requestOptions).pipe(
      map((resp:any) => {
        let lista_marcas = resp['data'];
        lista_marcas.forEach(marca => {
          marca.selected = false;
          marca.type='brand';
        });
        return lista_marcas||[];
      }),
    )
    return request;
  }
  listMarcasV2(params) {
    const requestOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${environment.token}`
      })
    };
    let url = `${environment.api_prod}${environment.version_prod}mark`;
    if (params.categoryID) {
      url += `/category/${params.categoryID}`;
    } else if (params.familyID) {
      url += `/family/${params.familyID}`;
    } else if (params.subfamilyID) {
      url += `/subFamily/${params.subfamilyID}`;
    }
    let request = this.http.get(url, requestOptions).pipe(
      map((resp: any) => {
        let lista_marcas = resp['data'];
        lista_marcas.forEach(marca => {
          marca.selected = false;
          marca.type = 'brand';
        });
        return lista_marcas || [];
      }),
    );
    return request;
  }
  listFilter(params){
    const requestOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${environment.token}`
      })
    };
    let request = this.http.get(`${environment.api_prod}${environment.version_prod}category/filter?${params}`, requestOptions).pipe(
      map((resp:any) => {
        let categories = resp['data'];
        return categories||[];
      }),
    )
    return request;
  }
  infoProduccion(id){
    // this.sweetalertsService.loading();
    const requestOptions = {
      headers: new HttpHeaders({
        // Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
        Authorization: `Bearer ${environment.token}`
      })
    };
    let request = this.http.get(`${environment.api_prod}${environment.version_prod}product/bom/${id}`, requestOptions).pipe(
      // delay(1000),
      map((resp:any) => {
        return resp['data']||[];
      }),
    )
    return request;
  }
  login(body){
    this.sweetalertsService.loading();
    const requestOptions = {
      headers: new HttpHeaders({

      })
    };
    let request = this.http.post(`${environment.api_prod}${environment.version_prod}auth/login/user`, body, requestOptions).pipe(
    // let request = this.http.post(`${environment.api_test}${environment.version_test}auth/signin/admin`, body, requestOptions).pipe(
      // delay(1000),
      map((resp:any) => {
        this.sweetalertsService.dissmissSwal();
        if(!resp['error']){
          if(resp['data'].isVerified=='N'){
            console.info("resp['data']: ",resp['data']);
            // this.router.navigate(['/signin'], { queryParams: {step:2,email:body.email} });
            this.router.navigate(['/signin',{step:2,email:body.email}]);
            return;
          }
          this.userService.setCurrentUser(resp['data']);
          localStorage['ACCESS_TOKEN'] = resp['data']['token'];
        }
        setTimeout(() => {
          switch (this.userService.getRol()) {
            case 'admin':
              return this.router.navigateByUrl('/main/home');
            case 'Admin':
              return this.router.navigateByUrl('dmain/dashboard');
            case "UserWeb":
              return this.router.navigateByUrl('/main/home');            
            default:
              return this.router.navigateByUrl('/main/home');
          }
        }, 200);
        return resp;
      })
    )
    return request;
  }
  loginOnly(body){
    this.sweetalertsService.loading();
    const requestOptions = {
      headers: new HttpHeaders({

      })
    };
    let request = this.http.post(`${environment.api_prod}${environment.version_prod}auth/login/user`, body, requestOptions).pipe(
      map((resp:any) => {
        this.sweetalertsService.dissmissSwal();
        if(!resp['error']){
          // if(resp['data'].isVerified=='N'){
          //   console.info("resp['data']: ",resp['data']);
          //   this.router.navigate(['/signin',{step:2,email:body.email}]);
          //   return;
          // }
          this.userService.setCurrentUser(resp['data']);
          localStorage['ACCESS_TOKEN'] = resp['data']['token'];
        }
        return resp;
      })
    )
    return request;
  }
  signin(body){
    this.sweetalertsService.loading();
    const requestOptions = {
      headers: new HttpHeaders({

      })
    };
    let request = this.http.post(`${environment.api_prod}${environment.version_prod}auth/register/user`, body, requestOptions).pipe(
      // delay(1000),
      map((resp:any) => {
        this.sweetalertsService.dissmissSwal();
        // if(!resp['error']){
        //   this.userService.setCurrentUser(resp);
        //   localStorage['ACCESS_TOKEN'] = resp['token'];
        // }
        // setTimeout(() => {
        //   switch (this.userService.getRol()) {
        //     case 'admin':
        //       return this.router.navigateByUrl('/main/home');
        //     case 'Admin':
        //       return this.router.navigateByUrl('dmain/dashboard');
        //     case "usuario":
        //       return this.router.navigateByUrl('/main/home');            
        //     default:
        //       return this.router.navigateByUrl('/main/home');
        //   }
        // }, 200);
        return resp;
      })
    )
    return request;
  }
  checkPin(body,noroute?){
    this.sweetalertsService.loading();
    const requestOptions = {
      headers: new HttpHeaders({
  
      })
    };
    let request = this.http.post(`${environment.api_prod}${environment.version_prod}auth/checkPin`, body, requestOptions).pipe(
      // delay(1000),
      map((resp:any) => {
        this.sweetalertsService.dissmissSwal();
          if(!resp['error']){
            this.userService.setCurrentUser(resp['data']);
            localStorage['ACCESS_TOKEN'] = resp['token'];
          }
          if(!noroute){
            setTimeout(() => {
              switch (this.userService.getRol()) {
                case 'admin':
                  return this.router.navigateByUrl('/main/home');
                case 'Admin':
                  return this.router.navigateByUrl('dmain/dashboard');
                case "usuario":
                  return this.router.navigateByUrl('/main/home');            
                default:
                  return this.router.navigateByUrl('/main/home');
              }
            }, 200);
          }
        return resp;
      })
    )
    return request;
  }
  userAgents(){
    this.sweetalertsService.loading();
    const requestOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${environment.token}`
      })
    };
    let request = this.http.get(`${environment.api_prod}${environment.version_prod}user/agents`, requestOptions).pipe(
      // delay(1000),
      map((resp:any) => {
        this.sweetalertsService.dissmissSwal();
        return resp;
      })
    )
    return request;
  }
  sendPin(body){
    this.sweetalertsService.loading();
    const requestOptions = {
      headers: new HttpHeaders({
  
      })
    };
    let request = this.http.post(`${environment.api_prod}${environment.version_prod}auth/sendPin`, body, requestOptions).pipe(
      // delay(1000),
      map((resp:any) => {
        this.sweetalertsService.dissmissSwal();
  
        return resp;
      })
    )
    return request;
  }
  productBash(body){
    // this.sweetalertsService.loading();
    const requestOptions = {
      headers: new HttpHeaders({
  
      })
    };
    let request = this.http.put(`${environment.api_prod}${environment.version_prod}product/bash`, body, requestOptions).pipe(
      // delay(1000),
      map((resp:any) => {
        // this.sweetalertsService.dissmissSwal();
  
        return resp;
      })
    )
    return request;
  }
  resetPassword(body){
    this.sweetalertsService.loading();
    const requestOptions = {
      headers: new HttpHeaders({
  
      })
    };
    let request = this.http.post(`${environment.api_prod}${environment.version_prod}auth/resetPassword`, body, requestOptions).pipe(
      // delay(1000),
      map((resp:any) => {
        this.sweetalertsService.dissmissSwal();
  
        return resp;
      })
    )
    return request;
  }
  sendMail(body){
    this.sweetalertsService.loading();
    const requestOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': `Bearer ${environment.access_token}`
      })
    };
    let request = this.http.post(`${environment.api_prod}${environment.version_prod}utils/sendMailVenta`, body, requestOptions).pipe(
      // delay(1000),
      map((resp:any) => {
        this.sweetalertsService.dissmissSwal();
        return resp;
      })
    )
    return request;
  }
  getDepartament(){
    // this.sweetalertsService.loading();
    const requestOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': `Bearer ${environment.access_token}`
      })
    };
    let request = this.http.get(`${environment.api_prod}${environment.maquinarias_prod}departament`, requestOptions).pipe(
      // delay(1000),
      map((resp:any) => {
        // this.sweetalertsService.dissmissSwal();
        return resp;
      })
    )
    return request;
  }
  getProvince(departamentID){
    // this.sweetalertsService.loading();
    const requestOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': `Bearer ${environment.access_token}`
      })
    };
    let request = this.http.get(`${environment.api_prod}${environment.maquinarias_prod}province?departamentID=${departamentID}`, requestOptions).pipe(
      // delay(1000),
      map((resp:any) => {
        // this.sweetalertsService.dissmissSwal();
        return resp;
      })
    )
    return request;
  }
  getDistrict(provinceID){
    // this.sweetalertsService.loading();
    const requestOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': `Bearer ${environment.access_token}`
      })
    };
    let request = this.http.get(`${environment.api_prod}${environment.maquinarias_prod}district?provinceID=${provinceID}`, requestOptions).pipe(
      // delay(1000),
      map((resp:any) => {
        // this.sweetalertsService.dissmissSwal();
        return resp;
      })
    )
    return request;
  }
  getFormToken(body:any){
    console.info("getFormToken:",localStorage['ACCESS_TOKEN']);
    let url = 'https://apis.simplex-erp.com/ecommerce/Aparka/v1/izipay/getFormTest';
    const requestOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': `Bearer ${environment.access_token}`
      })
    };
    let request = this.http.post( url , body ,requestOptions).pipe(
      map((resp) => {
        return resp;
      })
    )
    return request;
  }
  // card_guardar(body){
  //   this.sweetalertsService.loading();
  //   const requestOptions = {
  //     headers: new HttpHeaders({
  //       'Authorization':'Bearer '+this.utilsService.getLocalStorage('currentUser').token
  //       // 'Content-Type':  'application/x-www-form-urlencoded'
  //     })
  //   };
  //   let request = this.http.post(environment.api_url+this.getEnvVersion()+ '/card', body, requestOptions).pipe(
  //     delay(1000),
  //     map((resp:any) => {
  //       this.sweetalertsService.dissmissSwal();
  //       return resp;
  //     })
  //   )
  //   return request;
  // }

  /*
  *
  *
  * *
  * *
  * *
  * *
  * **OLD
  * *
  * *
  * *
  * *
  * 
  * 
  * */
  postServer2(method:string, params?:any){
    const URL = params?`https://apis.simplex-erp.com/ecommerce/api-ecommerce/v1/${method}?${params}`:`https://apis.simplex-erp.com/ecommerce/api-ecommerce/v1/${method}`;
    let request = this.http.get(URL, {headers: new HttpHeaders({
      //'Content-Type': 'application/json; charset=utf8'
      'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMDI0NTUxIiwicm9sZSI6IlVzdWFyaW8iLCJjb21wYW55SUQiOiIxMDAwMDUzIiwiY29tcGFueUNvZGUiOiJTVUJBQ09NIiwiaWF0IjoxNjY0MzMyNTQ2fQ.wkwD7CsUPDqcrA0lJ15nuCym4eLcYO26t6qvixoWMeU`
    })} )
    // return request;
    /*
    .pipe(map((resp)=>{
      return resp;
    }));
    */
    return request;
  }
  /*
  *
  *
  * *
  * *
  * *
  * *
  * **UTILS
  * *
  * *
  * *
  * *
  * 
  * 
  * */
  getSafeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
